import React, { useEffect, useState, useRef } from "react"
// import ReactSummernote from "react-summernote"
import "bootstrap/dist/css/bootstrap.css"
import "react-summernote/dist/react-summernote.css" // import styles
import "./AddThread.scss"

import { Modal } from "react-bootstrap"
import { getThreadCategory } from "@repository/ThreadCategoryRepository"
import { createThread } from "@repository/ThreadRepository"
import { getMe } from "@repository/UserRepository"

function AddThread() {
  const coverPicture = useRef(null);
  const [popularCategories, setPopularCategories] = useState([])
  const [searchText, setSearchText] = useState("")
  const [isLoadCategoriesData, setIsLoadCategoriesData] = useState(true)
  const [isPostingThread, setIsPostingThread] = useState(false)
  const [threadTitle, setThreadTitle] = useState("")
  const [threadBody, setThreadBody] = useState("")
  const [threadCategoryId, setThreadCategoryId] = useState("")
  const [isShowSuccessAlert, setIsShowSuccessAlert] = useState(false)
  const [user, setUser] = useState(null)
  const [isLoadUserData, setIsLoadUserData] = useState(true)
  const [coverFile, setCoverFile] = useState(null);
  const [coverUrl, setCoverUrl] = useState(null);
  const [isPreviewContent, setIsPreviewContent] = useState(false);
  const [errors, setErrors] = useState({});
  let ReactSummernote = ''

  if (typeof window !== `undefined`) {
    window.Popper = require("popper.js").default
    window.$ = window.jQuery = window.jquery = require("jquery")
    ReactSummernote = require("react-summernote").default
    require("bootstrap/js/dist/modal")
    require("bootstrap/js/dist/dropdown")
    require("bootstrap/js/dist/tooltip")
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
    handleGetCategories()
    handleGetUser()
  }, []);

  const handleGetCategories = () => {
    setIsLoadCategoriesData(true)
    getThreadCategory()
      .then(res => {
        setIsLoadCategoriesData(false)
        setPopularCategories(res.data.data)
      })
      .catch(err => {
        setIsLoadCategoriesData(false)
        console.log(err.response)
      })
  }

  const handleGetUser = () => {
    setIsLoadUserData(true)
    getMe()
      .then(res => {
        setUser(res.data)
        setIsLoadUserData(false)
      })
      .catch(err => {
        setIsLoadUserData(false)
        console.log(err.response)
      })
  }

  const handleUploadImage = image => {
    const reader = new FileReader()
    reader.readAsDataURL(image[0])
    reader.onloadend = () => {
      ReactSummernote.insertImage(reader.result)
    }
  }

  const hasValidInputs = () => {
    if (!threadBody || !threadTitle.trim() || !threadCategoryId) {
      return false
    }
    return true
  }

  const handlePostThread = () => {
    setIsPostingThread(true)
    let data = {
      thread_category_id: threadCategoryId,
      user_id: user._id,
      title: threadTitle,
      body: threadBody,
      image: coverFile,
    };

    let formData = new FormData();

    for (let key in data) {
      if (data[key] !== null) {
        formData.append(key, data[key]);
      }
    }

    createThread(formData)
      .then(res => {
        setIsShowSuccessAlert(true)
        setIsPostingThread(false)
        setThreadCategoryId("")
        setThreadBody("")
        setThreadTitle("")
        setCoverFile(null)
        setCoverUrl(null)
      })
      .catch(err => {
        setIsPostingThread(false)
        if (err.response.status === 422) {
          setErrors(err.response.data.errors)
        }
        console.log(err.response)
      })
  }

  const handleUploadCover = (e) => {
    let file = e.target.files[0];

    if (!file) {
      setCoverFile(null);
      setCoverUrl(null);
      return;
    }

    let reader = new FileReader();
    reader.onloadend = () => {
      setCoverFile(file);
      setCoverUrl(reader.result);
    }
    reader.readAsDataURL(file);
  }

  return (
    <div
      id="add-thread"
      className="container py-5 d-flex flex-column flex-grow-1"
    >
      <input
        type="file"
        ref={coverPicture}
        onChange={handleUploadCover}
        accept="image/*"
        className="d-none"
      />
      <Modal
        show={isPreviewContent}
        onHide={() => setIsPreviewContent(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Thread Cover
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <img src={coverUrl} className="w-100" />
        </Modal.Body>
      </Modal>
      {isLoadCategoriesData ? (
        <div className="mdi mdi-loading mdi-spin mdi-48px m-auto" />
      ) : (
        <>
          {isShowSuccessAlert && (
            <div className="asap-body text-philadelphia-blue py-2 px-3 alert-success mb-3">
              Thread posted successfully.
            </div>
          )}
          <div className="dosis-title-1 mb-3">Post New Thread</div>
          <div className="row">
            <div className="col-lg-4 order-lg-1 order-2">
              <div className="choose-thread-wrapper">
                <div className="dosis-headline text-iron-gray bg-abalone-gray px-3 py-2">
                  Choose Thread Category
                </div>
                <div className="p-3">
                  <div className="form-group d-flex flex-column-reverse justify-content-center position-relative">
                    <div className="mdi mdi-magnify text-abalone-gray position-absolute" />
                    <input
                      className="form-control"
                      placeholder="Search Category..."
                      onChange={e => setSearchText(e.target.value)}
                      value={searchText}
                      required
                    />
                  </div>
                  <div className="categories-wrapper">
                    {popularCategories
                      .filter(popularCategory => {
                        let _popularCategory = popularCategory.title
                          .trim()
                          .toLowerCase()
                        let _searchText = searchText.trim().toLowerCase()
                        return _popularCategory.includes(_searchText)
                      })
                      .map(popularCategory => (
                        <div
                          className={`d-flex justify-content-between cursor-pointer pb-2 mb-3 mr-2 category`}
                          onClick={() =>
                            setThreadCategoryId(popularCategory._id)
                          }
                          key={popularCategory._id}
                        >
                          <div
                            className={`asap-body ${
                              threadCategoryId == popularCategory._id
                                ? "text-philadelphia-blue"
                                : "text-iron-gray"
                            }`}
                          >
                            {popularCategory.title}
                          </div>
                          {threadCategoryId == popularCategory._id && (
                            <div className="mdi mdi-check text-philadelphia-blue" />
                          )}
                        </div>
                      ))}
                    {popularCategories.filter(popularCategory => {
                      let _popularCategory = popularCategory.title
                        .trim()
                        .toLowerCase()
                      let _searchText = searchText.trim().toLowerCase()
                      return _popularCategory.includes(_searchText)
                    }).length === 0 && (
                      <div className="w-100 h-100 d-flex flex-1 align-self-center justify-content-center">
                        <div className="asap-body text-harbor-gray align-self-center">
                          Category Not Found
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <button
                className="button-sm button-primary mt-3 d-lg-none d-block"
                disabled={isPostingThread || !hasValidInputs()}
              >
                {isPostingThread ? (
                  <div className="mdi mdi-loading mdi-spin" />
                ) : (
                  "Post Thread"
                )}
              </button>
            </div>
            <div className="col-lg-8 mb-lg-0 mb-3 order-lg-2 order-1">
              <div className="dosis-headline mb-2">
                Thread Cover <span className="asap-body text-steel-gray">(Optional)</span>
              </div>
              <div className="asap-body mb-2">
                This will be your thread cover image. 
              </div>
              {coverFile ? (
                <div>
                  <div
                    className={`previewed-image position-relative  ${errors.image ? 'mb-2' : 'mb-3'}`}
                    style={{
                      backgroundImage: `url(${coverUrl ? coverUrl : ''})`
                    }}
                  >
                    <div className="icon-delete-wrapper" onClick={() => {
                      setCoverUrl(null);
                      setCoverFile(null);
                    }}>
                      <div className="mdi mdi-delete text-pure-white mdi-18px" />
                    </div>

                    <div className="icon-zoom-wrapper" onClick={() => {
                      setIsPreviewContent(true);
                    }}>
                      <div className="mdi mdi-magnify-plus-outline text-pure-white mdi-18px" />
                    </div>
                  </div>
                  {errors.image && (
                    <div className="asap-caption-1 text-apple-red mb-3">{errors.image[0]}</div>
                  )}
                </div>
              ) : (
                <button
                  className="button-sm button-primary mb-3"
                  onClick={() => coverPicture.current.click()}
                >
                  <i className="mdi mdi-upload mdi-18px" /> Upload Cover
                </button>
              )}
              <div className="dosis-headline mb-2">
                Thread Details
              </div>
              <div className={"form-group d-flex flex-column-reverse mb-3"}>
                <input
                  id="thread-title"
                  className="form-control"
                  placeholder="Enter Thread Title"
                  value={threadTitle}
                  onChange={e => setThreadTitle(e.target.value)}
                  onKeyPress={event => {
                    if (
                      event.key === "Enter" &&
                      !(isPostingThread || !hasValidInputs())
                    ) {
                      handlePostThread()
                    }
                  }}
                  required
                />
                <label htmlFor="thread-title" className="input-label">
                  Thread Title
                </label>
              </div>
              {typeof window !== "undefined" && (
                <ReactSummernote
                  id="thread-content"
                  options={{
                    height: 350,
                    dialogsInBody: true,
                    toolbar: [
                      [ 'style', [ 'style' ] ],
                      [ 'font', [ 'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear'] ],
                      // [ 'fontname', [ 'fontname' ] ],
                      [ 'fontsize', [ 'fontsize' ] ],
                      [ 'color', [ 'color' ] ],
                      [ 'para', [ 'ol', 'ul', 'paragraph', 'height' ] ],
                      [ 'table', [ 'table' ] ],
                      [ 'insert', [ 'link'] ],
                      [ 'view', [ 'undo', 'redo', 'fullscreen', 'codeview', 'help' ] ]
                  ]
                    // toolbar: [
                    //   ["style", ["style"]],
                    //   ["font", ["bold", "underline", "clear"]],
                    //   ["fontname", ["fontname"]],
                    //   ["para", ["ul", "ol", "paragraph"]],
                    //   ["table", ["table"]],
                    //   ["insert", ["link", "picture", "video"]],
                    //   ["view", ["fullscreen", "codeview"]],
                    // ],
                  }}
                  onChange={setThreadBody}
                  value={threadBody}
                  onImageUpload={handleUploadImage}
                />
              )}
              <button
                className="button-sm button-primary mt-3 d-lg-block d-none"
                disabled={isPostingThread || !hasValidInputs()}
                onClick={handlePostThread}
              >
                {isPostingThread ? (
                  <div className="mdi mdi-loading mdi-spin" />
                ) : (
                  "Post Thread"
                )}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default AddThread
